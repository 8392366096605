<template>
  <div
    v-loading="loading"
    class="d-flex border rounded align-items-center justify-content-center"
    :style="{ height: '30rem' }"
  >
    <div v-if="isStocktaking" class="d-flex flex-column gap-6 align-items-center">
      <div>
        <p>{{ $t('orders.emptyCart.productAdditionNotCompleted') }}</p>
      </div>
      <div class="d-flex flex-column align-items-center gap-4">
        <Button type="primary" class="action-btn" @click="handleAction">
          <span class="d-flex align-items-center" :style="{ gap: '0.25rem' }">
            <ClipboardChecklistIcon :size="20" />
            {{ $t('orders.returnToStocktaking') }}
          </span>
        </Button>
        <Button type="secondary" class="action-btn" @click="handleReset">
          <span class="d-flex align-items-center">
            <ResetNewIcon :size="20" />
            <span :style="{ marginInlineStart: '1.5rem' }">{{ $t('orders.resetOrder') }}</span>
          </span>
        </Button>
      </div>
    </div>
    <div v-else class="d-flex flex-column gap-5 align-items-center">
      <div class="d-flex flex-column gap-2 align-items-center" :style="{ width: '15rem' }">
        <p class="fw-bolder fs-6">{{ $t('orders.emptyCart.title') }}</p>
      </div>
      <div class="d-flex gap-6">
        <CartModeSelector
          v-if="canAddRecommendedProducts"
          :loading="patchOrderProcessItemsLoading"
          :title="$t('orders.emptyCart.orderByStockTaking')"
          :description="$t('orders.emptyCart.orderByStockTakingDesc')"
          @click="$emit('on-start-stocktaking')"
        >
          <template #icon>
            <EmptyClipboardChecklistIcon :size="40" />
          </template>
        </CartModeSelector>
        <el-tooltip
          v-else
          placement="top"
          :content="$t('orders.emptyCart.noItemsInPurchaseList')"
          popper-class="no-recommended-items-tooltip"
          :popper-options="{
            boundariesElement: 'div',
          }"
        >
          <CartModeSelector
            :disabled="true"
            :title="$t('orders.emptyCart.orderByStockTaking')"
            :description="$t('orders.emptyCart.orderByStockTakingDesc')"
          >
            <template #icon>
              <EmptyClipboardChecklistIcon :size="40" />
            </template>
          </CartModeSelector>
        </el-tooltip>
        <CartModeSelector
          :title="$t('orders.emptyCart.addProductsFromCatalog')"
          :description="$t('orders.emptyCart.addProductsFromCatalogDesc')"
          @click="$emit('on-show-catalog')"
        >
          <template #icon>
            <EmptyCartCatalogIcon :size="40" />
          </template>
        </CartModeSelector>
      </div>
    </div>
  </div>
</template>

<script>
import {
  EmptyCartCatalogIcon,
  EmptyClipboardChecklistIcon,
  ClipboardChecklistIcon,
  ResetNewIcon,
} from '@/assets/icons';
import { Button } from '@/modules/core';

import CartModeSelector from './CartModeSelector';

export default {
  name: 'EmptyCart',
  components: {
    Button,
    ResetNewIcon,
    EmptyCartCatalogIcon,
    CartModeSelector,
    ClipboardChecklistIcon,
    EmptyClipboardChecklistIcon,
  },
  props: {
    loading: { type: Boolean, default: false },
    canAddRecommendedProducts: { type: Boolean, default: false },
    patchOrderProcessItemsLoading: { type: Boolean, default: false },
    isStocktaking: { type: Boolean, default: true },
  },
  emits: ['on-start-stocktaking', 'on-reset-order'],
  setup(props, { emit }) {
    const handleAction = () => {
      if (props.isStocktaking) {
        emit('on-start-stocktaking');
      }
    };

    const handleReset = () => {
      emit('on-reset-order');
    };

    return { handleReset, handleAction };
  },
};
</script>
<style lang="scss" scoped>
.no-recommended-items-tooltip {
  width: 10rem !important;
  padding: 0.4rem !important;
  text-align: center !important;
}

.action-btn {
  height: 2.5rem;
  width: 11.5rem;
  margin: 0px !important;
}
</style>
