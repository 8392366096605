<template>
  <div class="input-marks-wrapper" :class="{ 'not-available': !isAvailable, available: isAvailable }">
    <span class="input-mark-v" @click="onIncludeClick"> <VIcon /> </span>
    <span class="divider-wrapper">
      <span class="divider" />
    </span>
    <span class="input-mark-x" :class="isDisabled ? 'mark-disabled' : ''" @click="onExcludeClick">
      <XIcon :fill="isDisabled ? 'white' : undefined" />
    </span>
  </div>
</template>

<script>
import { VIcon, XIcon } from '@/assets/icons';

export default {
  name: 'InputMarks',
  components: {
    VIcon,
    XIcon,
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isAvailable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['on-include', 'on-exclude'],
  setup(props, { emit }) {
    const onIncludeClick = () => (props.isAvailable ? emit('on-include') : null);

    const onExcludeClick = () => (props.isAvailable ? emit('on-exclude') : null);

    return {
      onIncludeClick,
      onExcludeClick,
    };
  },
};
</script>

<style lang="scss" scoped>
.input-marks-wrapper,
.input-mark-v,
.input-mark-x {
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.input-mark-v,
.input-mark-x {
  background: #f3f3f4;
  flex: 0.5;
  height: 100%;
}

.available {
  .input-mark-v,
  .input-mark-x {
    cursor: pointer;

    &:hover {
      background: #e3e4e6 !important;
    }

    &.mark-disabled {
      background: #e52044 !important;
    }
  }
}

.input-marks-wrapper {
  height: 28px;
}

.not-available {
  opacity: 0.3;
}

.input-mark-v {
  border-radius: 0px 8px 8px 0px;
}

.input-mark-x {
  border-radius: 8px 0px 0px 8px;
}

.divider-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f3f4;
  height: 100%;
}

.divider {
  width: 1px;
  background: #e3e4e6;
  height: 20px;
}
</style>
